import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { BackButton } from "../../controls/BackButton";
import { NavigationLinks } from "../../helper/NavigationLinks";

export default function CanvasParent() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "150px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							CANVAS PARENT
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							The Canvas Parent mobile application comprises two distinct apps, specifically developed for the Android and iOS platforms. These apps are integral components of the Canvas ecosystem (by the company Instructure), which encompasses a comprehensive Learning Management
							System. The application predominantly serves parents, mentors, and supervisors, aiming to deliver essential information concerning students' progress. The overarching objective of the app is to facilitate effective bidirectional communication between parents and the
							school, fostering seamless engagement and collaboration. The application is strategically tailored to cater to mid-school and high school students and their families, with a focus on specific geographic areas, namely APAC (Asia-Pacific) and USA (United States). While
							Canvas enjoys remarkable popularity within the North American region, its utilization extends across the globe, with a pervasive presence on every continent.
						</Typography>
						<Box pt={4}>
							<Typography variant="h5">About the apps</Typography>
							<Typography variant="body1" component={"div"} pt={2} sx={{ fontWeight: "400" }}>
								The apps are widely praised by the audience. Keeping it like that is not happening by accident. It requires hard work.
								<ul style={{ textAlign: "left" }}>
									<li>iOS App Store rating: 4.4 (116.000 ratings)</li>
									<li>Android Play Store rating: 4.3 (195.000 ratings)</li>
									<li>Daily Active Users on iOS: 70.000</li>
									<li>Daily Active Users on Android: 75.000</li>
									<li>Crash rate: &lt;0.03%</li>
									<li>
										Supported device types:
										<ul>
											<li>iOS - iPhone</li>
											<li>iOS - iPad</li>
											<li>Android - Phones with access to Play Store</li>
											<li>Android - Tablets with access to Play Store</li>
										</ul>
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								As the sole product manager overseeing the Canvas mobile experience, I assumed full ownership of the product's mobile experience including the app's vision and strategy. My objective was to initiate a process of innovation within the application, ensuring that it
								serves as a valuable extension, complementing the features offered on the website. I firmly believe that mobile apps should not merely replicate the website's functionalities but rather enhance the overall user experience by leveraging their distinct capabilities to
								the fullest extent. While the web surface has multiple feature owners, I singularly held the responsibility of ensuring a seamless and high-standard user experience on the mobile platform, consistently maintaining parity with the web version. The nature of the mobile
								apps necessitates a significant reliance on server-side logic and APIs. However, these APIs do not fall within the purview of my team's responsibilities, resulting in a substantial portion of my role revolving around closely monitoring API changes and engaging in
								negotiations with other teams. Effectively influencing them to prioritize my team's needs, even if they may diverge from their own, is an integral aspect of my job.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Tasks</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>
										Product and Strategy
										<ul>
											<li>
												Define <strong>Vision</strong>, <strong>Mission</strong> and <strong>Strategy</strong>
											</li>
											<li>
												Define the <strong>Objectives</strong> and <strong>Key Results</strong> to achieve business and user value
											</li>
											<li>Align objectives with the company goals</li>
											<li>
												Perform risk analysis
												<ul>
													<li>Business, Value, Usability, Feasibility</li>
												</ul>
											</li>
											<li>Perform competitor and market analysis</li>
											<li>Enforce data-driven decision making</li>
											<li>Tear down the Strategy into valuable an executable smaller chunks (epics)</li>
											<li>Manage internal and external stakeholders</li>
											<li>Define and measure success</li>
											<li>Maximize product value</li>
											<li>
												Ensure full accessibility of the apps through internal and external auditing (according{" "}
												<Link href="https://www.w3.org/TR/WCAG21/" target="_blank">
													WCAG 2.1 AA
												</Link>
												)
											</li>
											<li>Track API changes</li>
											<li>Identify opportunities for growth</li>
										</ul>
									</li>
									<li>
										Discovery
										<ul>
											<li>Hypotheses/idea validation</li>
											<li>Execute extensive product researches (favoring the Design Thinking approach)</li>
											<li>Execute extensive UX researches</li>
											<li>Facilitate design workshops</li>
											<li>Perform weekly customer/user sessions</li>
										</ul>
									</li>
									<li>
										Analytics
										<ul>
											<li>Oversee and measure the development efficacy (KPIs)</li>
											<li>Maintain full blown velocity metrics</li>
											<li>
												Define and measure usage data
												<ul>
													<li>Focusing on user segmentation, journey and path, funnels and cohort analysis</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										Leadership
										<ul>
											<li>People management</li>
											<li>Build trust internally/externally</li>
											<li>Team coaching (one on one sessions, retrospective, personal growth support )</li>
											<li>Guiding and overseeing the engineering team of ten members, providing a clear product perspective and direction</li>
											<li>Define the way how the team delivers maximum value</li>
											<li>Conduct the Scrum methodology with its ceremonies</li>
											<li>Communicate and influence with web teams</li>
											<li>Mentoring</li>
										</ul>
									</li>
									<li>
										Communication
										<ul>
											<li>Giving talks at various conferences</li>
											<li>Write blog posts and articles</li>
										</ul>
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Achievements</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Increased the iOS App Store ratings by 0.1 points reaching the level of 4.4 (116.000 ratings)</li>
									<li>Increased the Android Play Store ratings by 0.3 point reaching the level of 4.3 (195.000 ratings)</li>
									<li>Successfully empowered and innovated the app</li>
									<li>Redesigned and refined the interfaces of the apps, enhancing usability and user satisfaction</li>
									<li>Induced and advocated the mobile-first strategy throughout the R&D organization</li>
									<li>Align product development with evolving market trends</li>
									<li>Enable deeper penetration into emerging markets (APAC)</li>
									<li>
										Made the apps fully accessible (according{" "}
										<Link href="https://www.w3.org/TR/WCAG21/" target="_blank">
											WCAG 2.1 AA
										</Link>
										)
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Tools</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Confluence, Jira, Miro, Figma, Maze, Google Analytics, FullStory, Heap, Clickup, draw.io, Google Apps, Google Forms</li>
								</ul>
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={2} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_1.jpeg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_2.jpeg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_3.jpeg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_4.jpeg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_5.jpg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_6.jpg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/canvas_parent_screen_7.jpg"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
