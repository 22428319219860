/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Link, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import { theme } from "../helper/JdTheme";
import { NavigationLinks } from "../helper/NavigationLinks";
import { JdContext } from "../helper/JdContext";

//**********************************************************************************************
// INTERFACE
//**********************************************************************************************
export interface IHeaderProps {
	visible?: boolean;
}

//**********************************************************************************************
// COMPONENT
//**********************************************************************************************
export const Footer: FC<IHeaderProps> = (props: IHeaderProps) => {
	const context = useContext(JdContext);
	//**********************************************************************************************
	// RENDER
	//**********************************************************************************************
	return (
		<React.Fragment>
			<Box display={"flex"} justifyContent={context.mediaXS ? "center" : "space-between"} flexDirection={context.mediaXS ? "column" : "row"}>
				<Box>
					<Typography variant="body2" color="text.secondary" align={context.mediaXS ? "center" : "left"} lineHeight={"60px"}>
						{"Copyright © "}
						{new Date().getFullYear()}{" "}
						<Link color={theme.palette.primary.main} href="https://jozsefdavid.com">
							jozsefdavid.com
						</Link>{" "}
					</Typography>
				</Box>
				<Box p={1} sx={{ color: "#cccccc", lineHeight: "40px" }}>
					<Link color={theme.palette.primary.main} href="https://www.linkedin.com/in/talkischeapshowmethecode/" target="_blank">
						LinkedIn
					</Link>{" "}
					|{" "}
					<Link color={theme.palette.primary.main} href="https://medium.com/@jzfdvd" target="_blank">
						Blog
					</Link>{" "}
					|{" "}
					<Link color={theme.palette.primary.main} href={NavigationLinks.CONTACT}>
						Email
					</Link>{" "}
					|{" "}
					<Link color={theme.palette.primary.main} href={NavigationLinks.CONTACT}>
						Phone
					</Link>{" "}
					|{" "}
					<Link color={theme.palette.primary.main} href="https://jozsefdavid.com">
						Website
					</Link>
					{/* <IconCirclePhone width={48} height={48} fill={"#cccccc"} stroke={"none"} />
								<IconCircleLinkedIn width={48} height={48} fill={"#cccccc"} stroke={"none"} /> */}
				</Box>
			</Box>
		</React.Fragment>
	);
};
