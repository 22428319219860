import { Container, Grid } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { Footer } from "../controls/Footer";

// import { JdContext } from "../helper/JdContext";

export interface ILayoutProps {
	header: ReactElement;
	content: ReactElement;
}

export const Layout: FC<ILayoutProps> = (props: ILayoutProps) => {
	// const context = useContext(JdContext);
	const { content, header } = props;

	return (
		<React.Fragment>
			{header}
			<Container maxWidth="lg">
				{content}
				{/* FOOTER */}
				<Grid item xs={12} mt={18} textAlign="left" sx={{ textAlign: "center" }}>
					<Footer />
				</Grid>
			</Container>
		</React.Fragment>
	);
};
