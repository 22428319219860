import { AlertColor } from "@mui/material";
import { createGlobalState } from "react-hooks-global-state";

export interface INetworkModel {
	ip: string;
	city: string;
	region: string;
}
export interface IGlobalState {
	snackVisible: boolean;
	snackMessage: string;
	snackSeverity: AlertColor;
	snackDuration: number;
	progressIndicatorVisible: boolean;
	network: INetworkModel;
}

export const { useGlobalState } = createGlobalState({
	snackVisible: false,
	snackMessage: "",
	snackSeverity: "info",
	snackDuration: 3000,
	progressIndicatorVisible: false,
	network: null as any as INetworkModel,
} as IGlobalState);
