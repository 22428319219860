import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function Wikicar() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "550px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							WIKICAR
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							WikiCar is a user-centric and knowledge-rich website tailored to car enthusiasts seeking comprehensive information about vehicles they own or wish to purchase. The site provides in-depth technical and non-technical details, empowering users with valuable insights to make
							informed decisions. A key highlight is our exclusive collection of links to original brochures directly from manufacturers—a delightful feature that resonates with passionate car lovers, particularly given the challenge of finding these resources elsewhere.
							<br />
							<br />
							As a product manager, I recognized the significance of seamless data integration, and the site diligently pulls information from various reliable data sources. However, I must acknowledge that integrating certain data sources posed complex challenges from both business
							and technology perspectives. Yet, we persevered, ensuring a smooth data flow to guarantee an exceptional user experience.
							<br />
							<br />
							I continue to place paramount importance on user satisfaction and continuous improvement. My commitment to providing a comprehensive and delightful car exploration journey is unwavering. As I pave the way for future enhancements, stay tuned for more exciting details and
							features coming soon.
							<br />
							<br />
							WikiCar strives to be the go-to destination for automotive enthusiasts, enhancing the vehicle research and buying process for users worldwide. From intricate technical details to rare brochures, I leave no stone unturned in delivering a premium user experience and
							ensuring WikiCar remains a cherished resource for car enthusiasts of all backgrounds.{" "}
						</Typography>

						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" component={"div"} pt={2} sx={{ fontWeight: "400" }}>
								Including WikiCar in this list was a thoughtful decision, considering the history behind its inception. From a partner management perspective, it proved to be an engaging venture, demanding advanced interpersonal and negotiation skills. I reached out to over 10 data
								providers, exploring both free and paid data options. Negotiating terms, especially with limited finances, posed a significant challenge. The technological integration, another crucial aspect, demanded rigorous effort and dedication, keeping me awake through countless
								nights.
								<br />
								<br />
								While the site currently features Google Ads, I want to emphasize that monetization is not a priority at this stage. Instead, our focus remains on delivering a valuable experience for our users, ensuring that WikiCar serves as a reliable and informative resource for
								car enthusiasts worldwide. As a product manager, I am committed to continually enhancing the platform and strengthening its partnership networks, fostering long-term growth and providing users with an unparalleled automotive exploration journey.{" "}
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={4} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_wikicar_screen_1.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_wikicar_screen_2.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_wikicar_screen_3.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_wikicar_screen_4.png"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
