import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function Chemlocator() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "350px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							CHEMLOCATOR{" "}
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							ChemLocator is an unstructured chemical search engine. Unstructured search is a process of searching for chemical information without the use of pre-defined, standardized formats or specific databases. Unstructured search allows users to input natural language queries or
							free-text descriptions to find relevant chemical information. ChemLocator utilizes advanced algorithms and natural language processing techniques to understand the context and intent of the user's query which enables more flexible and intuitive searches. ChemLocator is
							useful (value proposition) when dealing with vast and diverse chemical data, where structured search methods may not cover all possible variations of chemical names, synonyms, or related terms. By leveraging unstructured search capabilities, researchers, scientists, and
							chemists can efficiently access and analyze chemical information from various sources, facilitating more comprehensive and accurate data retrieval.
						</Typography>

						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								As the originator of the product concept, I had the opportunity to present my ideas to the C-level executives of the company and successfully persuade them about the viability of the concept. Taking the initiative, I embarked on launching ChemLocator from the ground
								up, starting without a team or resources. After conducting thorough research to validate the initial hypothesis, I secured a few months to assemble a team and develop the first version of the product. Throughout the product's journey, I served as the sole product
								manager, overseeing its growth and expansion. With my efforts, ChemLocator attained remarkable success, gaining traction among top 20 pharmaceutical companies and acquiring customers worldwide, from the USA to Japan. As the sole product manager, I held complete
								ownership, leading it from inception to realization. My responsibilities extended beyond conventional product management tasks, encompassing team resource management, budgeting, marketing, promotional activities, and customer support. This holistic approach allowed me
								to drive the product's success, securing its position in the global market and establishing it as a valuable asset to diverse pharmaceutical stakeholders.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Tasks</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>
										Product and Strategy
										<ul>
											<li>Penetrate the market with a new product</li>
											<li>Find the growth track</li>
											<li>
												Define <strong>Vision</strong>, <strong>Mission</strong> and <strong>Strategy</strong>
											</li>
											<li>
												Define the <strong>Objectives</strong> and <strong>Key Results</strong> to achieve business and user value
											</li>
											<li>Align objectives with the company goals</li>
											<li>Define and measure success</li>
											<li>Perform competitor and market analysis</li>
											<li>
												Perform continuous risk analysis
												<ul>
													<li>Business, Value, Usability, Feasibility</li>
												</ul>
											</li>
											<li>Manage uncertainty in the business model</li>
											<li>Manage internal and external stakeholders</li>
											<li>Backlog and roadmap management</li>
										</ul>
									</li>
									<li>
										Discovery
										<ul>
											<li>Validate initial hypothesis</li>
											<li>Reach product market fit</li>
											<li>Execute extensive product researches (favoring the Design Thinking approach)</li>
											<li>Execute extensive UX researches</li>
										</ul>
									</li>
									<li>
										Analytics
										<ul>
											<li>Oversee and measure the development efficacy (KPIs)</li>
											<li>Maintain full blown velocity metrics</li>
											<li>
												Define and measure usage data
												<ul>
													<li>Focusing on user segmentation, journey and path, funnels and cohort analysis</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										Leadership
										<ul>
											<li>People management, team coaching</li>
											<li>Build trust internally/externally</li>
											<li>Define product context</li>
											<li>Help setup engineering context</li>
											<li>Conduct the Scrum methodology with its ceremonies</li>
										</ul>
									</li>
									<li>
										Communication
										<ul>
											<li>Giving talks at various conferences worldwide</li>
											<li>Write blog posts and articles</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=YT10Kwe3Fn8&t=79s&ab_channel=Chemaxon" target="_blank">
													ChemLocator presentation in Boston, USA (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=heYUuRz2Gf4&ab_channel=Chemaxon" target="_blank">
													ChemLocator online presentation (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=cXhLIfFc5pI&t=292s&ab_channel=Chemaxon" target="_blank">
													ChemLocator presentation in Cambridge, USA (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=pMFuk4UoXX4&t=307s&ab_channel=Chemaxon" target="_blank">
													ChemLocator vs JChem SharePoint presentation in Budapest, Hungary (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=0nkjwtrEuIY&ab_channel=Chemaxon" target="_blank">
													ChemLocator product demonstration (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=KFMz0Csd3qc&list=PLA3Ev2ngKC0RPlD39iX6O9xV5XEby_4Gf&index=9&ab_channel=Chemaxon" target="_blank">
													ChemLocator presentation, San Francisco, USA (YouTube)
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Achievements</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Bring a new product to life based on my idea</li>
									<li>Penetrate the pharma industry. Be there in the top 20 pharmaceutical companies </li>
									<li>Extend customer and user base internationally from USA to Japan</li>
									<li>Migrate customers from a legacy tool to ChemLocator</li>
									<li>Help select the technology stack</li>
									<li>Help form the software architecture</li>
									<li>Align product development with evolving market trends</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Tools</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Confluence, Jira, Google Analytics, Custom Analytics, draw.io, Google Apps, Google Forms</li>
								</ul>
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={2} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_chemlocator_screen_1.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_chemlocator_screen_2.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_chemlocator_screen_3.png"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
