import { Box, TextField } from "@mui/material";
import React, { FC } from "react";
import { useLongAnswerInput } from "react-google-forms-hooks";

export interface ILongAnswerInputProps {
	id: string;
	label: string;
	required: boolean;
}

export const LongAnswerInput: FC<ILongAnswerInputProps> = (props: ILongAnswerInputProps) => {
	const { id, label, required } = props;
	const { register } = useLongAnswerInput(id);

	return (
		<Box pt={2} pb={2}>
			{/* <textarea {...register()} /> */}
			<TextField label={label} multiline rows={4} placeholder="Your message" fullWidth required={required ? true : false} {...register()} />
		</Box>
	);
};
