import { createContext } from "react";

export class JdContextValue {
	public mediaXS: boolean;
	public mediaSM: boolean;
	public mediaMD: boolean;
	public mediaLG: boolean;
	public mediaXL: boolean;
	public selectedPictureSrc: string;
	public services: string;

	/**
	 *
	 */
	constructor() {
		this.mediaXS = true;
		this.mediaSM = false;
		this.mediaMD = false;
		this.mediaLG = false;
		this.mediaXL = false;
		this.selectedPictureSrc = "";
		this.services = "";
	}
}

export const JdContext = createContext(new JdContextValue());
