import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function JchemSharePoint() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "350px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							JCHEM SHAREPOINT
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							JChem for SharePoint was a software integration provided by ChemAxon, designed to seamlessly integrate chemical information and data management capabilities into Microsoft SharePoint environments. It allows users to store, search, and analyze chemical structures and
							associated data within SharePoint, enabling organizations to efficiently manage chemical-related content and collaborate on chemical projects. With JChem for SharePoint, users can leverage various chemical functionalities, such as structure search, property calculations,
							and chemical visualization, directly within their SharePoint document libraries. This integration enhances the efficiency of chemical data retrieval and analysis, supporting researchers, chemists, and other professionals in the life sciences, pharmaceuticals, and
							chemistry industries. The solution targeted the B2B market especially the enterprise sector since SharePoint was mostly utilized there. The application is discontinued.
						</Typography>

						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								I initially joined the project as a software architect before transitioning to product management. In the beginning, I assumed the role of a product owner for a period of 1-2 years, and gradually, my responsibilities expanded. As the sole product specialist on the
								team, I managed all aspects of product development without the support of dedicated UX or business analysts. Consequently, I undertook the planning and execution of research and validation projects independently. Since I lacked mentors or peers in product management
								on my team, I dedicated considerable effort to self-education and familiarizing myself with the intricacies of the role. To enhance my management skills, the company supported my professional growth by facilitating my attendance at various product conferences and
								workshops worldwide. During the subsequent years, specifically between 2015 and 2017, we made the strategic decision to retire the application. Consequently, I led a substantial migration campaign to transition our customers to a new platform known as{" "}
								<Link href={NavigationLinks.PRODUCTS_CHEMLOCATOR} target="_blank">
									ChemLocator
								</Link>
								. The campaign proved highly successful, resulting in the migration of over 80% of our customers to the new platform.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Tasks</Typography>
							<Typography variant="body1" component={"div"}  pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>
										Product and Strategy
										<ul>
											<li>
												Define <strong>Vision</strong>, <strong>Mission</strong> and <strong>Strategy</strong>
											</li>
											<li>
												Define the <strong>Objectives</strong> and <strong>Key Results</strong> to achieve business and user value
											</li>
											<li>Align objectives with the company goals</li>
											<li>
												Perform risk analysis
												<ul>
													<li>Business, Value, Usability, Feasibility</li>
												</ul>
											</li>
											<li>Perform competitor and market analysis</li>
											<li>Enforce data-driven decision making</li>
											<li>Manage internal and external stakeholders</li>
											<li>Define and measure success</li>
											<li>Manage product backlog</li>
											<li>Own product roadmap</li>
											<li>Identify growth opportunities</li>
										</ul>
									</li>
									<li>
										Discovery
										<ul>
											<li>Idea validation</li>
											<li>Execute extensive UX researches</li>
											<li>Market analysis (TAM, SAM, SOM)</li>
										</ul>
									</li>

									<li>
										Leadership
										<ul>
											<li>People management</li>
											<li>Taking over product role from an engineering (architect) position</li>
											<li>Build trust in the team</li>
											<li>Conduct the Scrum methodology with its ceremonies</li>
											<li>Communicate and influence with component providers</li>
										</ul>
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Achievements</Typography>
							<Typography variant="body1" component={"div"}  pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Increased the average deal sizes from 5 figures to 6 figures (USD).</li>
									<li>Migrate 80% of the customers to a new platform</li>
									<li>Successfully retiring the product</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Tools</Typography>
							<Typography variant="body1" component={"div"}  pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Microsoft SharePoint, Visual Studio, draw.io, Custom Analytics</li>
								</ul>
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={2} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_jchem_sharepoint_screen_1.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_jchem_sharepoint_screen_2.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_jchem_sharepoint_screen_3.png"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
