import React, { FC, Suspense, useMemo } from "react";
import { Box, CircularProgress, ThemeProvider, useMediaQuery } from "@mui/material";
import "./App.css";
import { JdContext } from "./helper/JdContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProgressIndicator } from "./controls/ProgressIndicator";
import { useGlobalState } from "./helper/GlobalState";
import { theme } from "./helper/JdTheme";
import { NavigationLinks } from "./helper/NavigationLinks";
import { ScrollToTop } from "./helper/ScrollToTop";
import CanvasTeacher from "./pages/products/CanvasTeacher";
import CanvasParent from "./pages/products/CanvasParent";
import Chemicalize from "./pages/products/Chemicalize";
import ChemicalizePro from "./pages/products/ChemicalizePro";
import Chemlocator from "./pages/products/Chemlocator";
import MarkushEditor from "./pages/products/MarkushEditor";
import JchemSharePoint from "./pages/products/JchemSharePoint";
import Walheda from "./pages/products/Walheda";
import Rebezle from "./pages/products/Rebezle";
import Wikicar from "./pages/products/Wikicar";
import Resume from "./pages/Resume";
import Contact from "./pages/Contact";
import Mentoring from "./pages/Mentoring";
import HowIProduct from "./pages/HowIProduct";

const Home = React.lazy(() => import("./pages/Home3"));
const CanvasStudent = React.lazy(() => import("./pages/products/CanvasStudent"));
const AllProducts = React.lazy(() => import("./pages/products/AllProducts"));

const App: FC = () => {
	const [progressIndicatorVisible] = useGlobalState("progressIndicatorVisible");

	const matchesXS = useMediaQuery("(min-width: 0px) and (max-width: 599px)");
	const matchesSM = useMediaQuery("(min-width: 600px) and (max-width: 899px)");
	const matchesMD = useMediaQuery("(min-width: 900px) and (max-width: 1199px)");
	const matchesLG = useMediaQuery("(min-width: 1200px) and (max-width: 1919px)");
	const matchesXL = useMediaQuery("(min-width: 1920px) and (max-width: 10000px)");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const contextValue = useMemo(
		() => ({
			mediaXS: matchesXS,
			mediaSM: matchesSM,
			mediaMD: matchesMD,
			mediaLG: matchesLG,
			mediaXL: matchesXL,
			selectedPictureSrc: "",
			services: "",
		}),
		[matchesXS, matchesSM, matchesMD, matchesLG, matchesXL]
	);

	console.log("xs " + matchesXS);
	console.log("sm " + matchesSM);
	console.log("md " + matchesMD);
	console.log("lg " + matchesLG);
	console.log("xl " + matchesXL);

	return (
		<ThemeProvider theme={theme}>
			<JdContext.Provider value={contextValue}>
				<Suspense
					fallback={
						<Box display="flex" alignItems="center" justifyContent="center" m={0} p={4}>
							<CircularProgress disableShrink />
						</Box>
					}
				>
					<BrowserRouter>
						<ScrollToTop />
						<Routes>
							<Route caseSensitive={false} path={NavigationLinks.HOME} element={<Home />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_CANVAS_STUDENT} element={<CanvasStudent />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_CANVAS_TEACHER} element={<CanvasTeacher />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_CANVAS_PARENT} element={<CanvasParent />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_CHEMICALIZE} element={<Chemicalize />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_CHEMICALIZE_PRO} element={<ChemicalizePro />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_CHEMLOCATOR} element={<Chemlocator />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_MARKUSH_EDITOR} element={<MarkushEditor />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_JCHEM_SHAREPOINT} element={<JchemSharePoint />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_REBEZLE} element={<Rebezle />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_WALHEDA} element={<Walheda />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_WIKICAR} element={<Wikicar />} />
							<Route caseSensitive={false} path={NavigationLinks.PRODUCTS_ALL_PRODUCTS} element={<AllProducts />} />
							<Route caseSensitive={false} path={NavigationLinks.RESUME} element={<Resume />} />
							<Route caseSensitive={false} path={NavigationLinks.CONTACT} element={<Contact />} />
							<Route caseSensitive={false} path={NavigationLinks.MENTORING} element={<Mentoring />} />
							<Route caseSensitive={false} path={NavigationLinks.HOW_I_PRODUCT} element={<HowIProduct />} />
						</Routes>
					</BrowserRouter>
				</Suspense>
				<ProgressIndicator open={progressIndicatorVisible} />
			</JdContext.Provider>
		</ThemeProvider>
	);
};

export default App;
