export class NavigationLinks {
	public static readonly HOME: string = "/";
	public static readonly PRODUCTS_CANVAS_STUDENT: string = "/products/canvas-student";
	public static readonly PRODUCTS_CANVAS_TEACHER: string = "/products/canvas-teacher";
	public static readonly PRODUCTS_CANVAS_PARENT: string = "/products/canvas-parent";
	public static readonly PRODUCTS_CHEMICALIZE: string = "/products/chemicalize";
	public static readonly PRODUCTS_CHEMICALIZE_PRO: string = "/products/chemicalize-pro";
	public static readonly PRODUCTS_CHEMLOCATOR: string = "/products/chemlocator";
	public static readonly PRODUCTS_MARKUSH_EDITOR: string = "/products/markush-editor";
	public static readonly PRODUCTS_JCHEM_SHAREPOINT: string = "/products/jchem-sharepoint";
	public static readonly PRODUCTS_REBEZLE: string = "/products/rebezle";
	public static readonly PRODUCTS_WALHEDA: string = "/products/walheda";
	public static readonly PRODUCTS_WIKICAR: string = "/products/wikicar";
	public static readonly PRODUCTS_ALL_PRODUCTS: string = "/products";
	public static readonly RESUME: string = "/resume";
	public static readonly CONTACT: string = "/contact";
	public static readonly MENTORING: string = "/mentoring";
	public static readonly HOW_I_PRODUCT: string = "/how-i-product";
}
