import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import { Header } from "../controls/Header";
import { JdContext } from "../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../helper/JdTheme";

export default function HowIProduct() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							HOW I PRODUCT
						</Typography>
						<Typography variant="h5" mt={2}>
							Let me guide you through one of my project and show you how I approach product management
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							In the dynamic landscape of project management, there is no one-size-fits-all recipe that guarantees success in every situation. The aim of this page is not to prescribe a singular approach but to highlight the common stages I typically navigate through in most projects.
							Drawing from my wealth of experience, I maintain an extensive toolkit that caters to each unique phase of the project, allowing me to adapt and apply relevant resources where they prove most beneficial. Embracing flexibility and adaptability, I strive to tailor my
							approach to the specific needs and complexities of each project. The following picture shows the stages and the yellow cards represent a tool I used in that stage previously.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_process.png"} alt="logo" width={"100%"} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Stages of "producting"
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body1" component={"div"} pb={0} pt={4} sx={{ fontWeight: "400" }}>
							As I have highlighted on this site, I have a strong inclination towards the Design Thinking approach. While it may not be rocket science, it captures the essence of common sense in an efficient manner. Design Thinking revolves around three primary stages to navigate a
							project: Understand, Explore, and Materialize. Similar to the iterative nature of agile development, this flow also follows a cyclic pattern. As I embark on a new project, I embrace a multifaceted approach, addressing it from three distinct angles:
							<ul>
								<li>
									<strong>Why </strong>are we doing it for who? (Problem space)
								</li>
								<li>
									<strong>What</strong> are we building? (Solution space)
								</li>
								<li>
									<strong>How </strong>we deliver the value? (Solution space)
								</li>
							</ul>
							These questions align seamlessly with the principles of Design Thinking, demonstrating their coherence and synergy. Here is how:
							<ul>
								<li>
									<strong>WHY and WHO</strong> is UNDERSTAND in Design Thinking. I split this phase into two smaller ones named: EMPATHIZE and DEFINE
								</li>
								<li>
									<strong>WHAT</strong> is EXPLORE in Design Thinking. I split this phase into three smaller ones named: IDEATE, PROTOTYPE and TEST
								</li>
								<li>
									<strong>HOW</strong> is MATERIALIZE in Design Thinking. I split this phase into two smaller ones named: IMPLEMENT and RELEASE
								</li>
								<li>
									<strong>BONUS:</strong> I am usually adding two extra phases to my projects: EVALUATE and COMMUNICATE
								</li>
							</ul>
						</Typography>
						<Typography variant="h4" pt={2} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							UNDERSTAND
						</Typography>
						<Typography variant="body1" component={"div"} pb={0} pt={4} sx={{ fontWeight: "400" }}>
							The most important goal of the understand phase for me is to come up with a clear <strong>problem statement</strong> or a clear <strong>user benefit</strong>. Every action I take will lead towards this goal. I will share the <strong>problem statement</strong> later in
							this section. The project was to build offline usage support for a mobile edTech application. First af all I had to dig deeper why this topic is even important for who? It is crucial since I had to win the sponsors. I took the following steps:
							<ul>
								<li>
									Collected historical internal information regarding the offline usage requests (1 weeks)
									<ul>
										<li>From community, sales, marketing, customer success, support and internally collected usage data. Fellow PMs from the APAC, LATAM, EMEA and US regions</li>
									</ul>
								</li>
								<li>
									Went through a deep market research (3 weeks)
									<ul>
										<li>Checked competitors and their solutions</li>
										<li>Researched TAM, SAM, SOM numbers </li>
										<li>
											Read through native mobile guides (
											<Link href="https://m2.material.io/design/guidelines-overview" target="_blank">
												Android
											</Link>
											,{" "}
											<Link href="https://developer.apple.com/design/human-interface-guidelines" target="_blank">
												iOS
											</Link>
											)
										</li>
										<li>Checked industry standards and expert reports</li>
										<li>Calculate a ballpark ROI </li>
									</ul>
								</li>
								<li>
									Performed risk analysis
									<ul>
										<li>
											I mostly analyze a project for four risk types: Business, Value, Usability and Feasibility. If they sound familiar to you, that is not by accident. I took them from the{" "}
											<Link href="https://www.svpg.com/" target="_blank">
												Silicon Valley Product Group
											</Link>{" "}
											materials and Marty Cagan
										</li>
										<li>Although in this stage only the Business and Value analysis were done at some level but later I refine them further</li>
									</ul>
								</li>
							</ul>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_competitors.png"} alt="logo" width={"100%"} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Part of competitor analysis
							</Typography>
						</Box>
					</Grid>

					{/* <Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={0}>
							<Typography variant="h5" mt={0}>
								UNDERSTAND AND EMPATHIZE
							</Typography>
							<Typography variant="body1" pt={0} sx={{ fontWeight: "400" }}></Typography>
						</Box>
					</Grid> */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={2}>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								At this stage, we had gained a foundational comprehension of the problem, drawing insights from both internal and external sources. This comprehensive analysis distinctly highlighted the longstanding unmet need, validating its existence for an extended period. This
								evidence alone served as a compelling demonstration of the problem's significance, further solidifying our resolve to proceed with the research.
							</Typography>
						</Box>
						<Box pt={2}>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								We gained a pretty good understanding from the research what others do good or bad, so I could come up with a basic list of key differentiators. This is only v0.1 but still very usefully for later orientation.
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<img src={"/project_differentiators.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Key differentiators
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h5">THE WHY?</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								As we continue our journey, the short problem statement remains elusive. My aim is always to distill the pain into a concise 1-2 sentence statement, capturing its essence succinctly. To achieve this, I initiated a comprehensive qualitative and quantitative research
								phase. Qualitative research involved conducting interviews with 17 institutions across Australia, Philippines, USA, and South Africa, allowing us to gain valuable insights directly from users and customers. Quantitative meant the surveys we ran in MAZE.
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<img src={"/project_survey_1.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Quantitative Survey Results
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={2}>
							<Typography variant="body1" component="div" pt={2} sx={{ fontWeight: "400" }}>
								Here I had enough data to come up with the problem statement/user benefit and answer the <strong>question WHY</strong>!
								<Box sx={{ borderLeft: "4px solid #5D8BD4ff", backgroundColor: "#D4A65D", borderRadius: "8px" }} p={3} mt={2}>
									<Typography variant="h6" sx={{ color: "#fff" }}>
										<i>
											{" "}
											The mobile app requires an active internet connection to function properly and prevent users from completing their core tasks in lack of reliable connection which results in decreasing performance and lower grades. Offline support allows users to access
											the app and complete those tasks at any time, anywhere.
										</i>{" "}
									</Typography>
								</Box>
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h5">THE WHO?</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								Another most important question to answer is <strong>for WHO</strong>? This means scoping. We had to find the segment we shoot for. This is how we were thinking:
								<ul>
									<li>In our pursuit of solving this challenge, we recognize that a one-size-fits-all approach may not be feasible.</li>
									<li>We sought to identify the most significant and demanding pain point and the key beneficiaries.</li>
									<li>It turned out the Students are the ones we must focus on first because, they outnumber other segments, their offline ability influences financially the decision makers and it might helps us penetrate new markets</li>
									<li>Students are the most critical segments since there are circumstances, regions where they have no other access to the learning system than a phone</li>
									<li>Notably, students, particularly those in high school and beyond, faced the most acute impact from this problem</li>
									<li>In certain circumstances and regions, access to the learning system solely via a mobile device was their only option</li>
									<li>Hence, we honed our focus on crafting a solution tailored to K12 and older students, with a primary focus on the APAC, LATAM, and African regions, and secondary focus on the USA and EMEA</li>
								</ul>
								And based on this we could start to build up a few user journeys!
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={2}>
							<Typography variant="h5">WHAT IS SUCCESS?</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								During the DEFINE stage, I not only establish the problem statement but also outline we deliver upon completion. It might be familiar to you since Value Proposition Canvas is part of the Business Model Canvas I am sure you already met.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_value_proposition.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Value Proposition Canvas
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={2}>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								During the DEFINE stage, I also outline the precise criteria that define success upon completion. While this may seem high-level at first glance, rest assured that there is a comprehensive document supporting each point with meticulous details and concrete facts.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_success_criteria.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Success Criteria
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h4" pt={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								EXPLORE
							</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								The phase of the journey involves navigating towards the optimal solutions for the identified problems. It typically unfolds across three key phases: <strong>Ideate, Prototype, and Test</strong>. It's important to note that this stage is where we seek answers to the
								pivotal question of <strong>"What to build?"</strong> As a follower of the SVPG approach, I value early engineering involvement in product design. However, I've observed that when it comes to problem discovery, a nuanced approach is needed. Engineers, in many cases,
								possess a distinct mindset. Their inherent focus lies in giving answers, implementing solutions, often leading them to immediately delve into the solution space. This eagerness, while well-intentioned, can sometimes pull back the process of comprehensively
								understanding the root of the problem especially during user interviews. It's worth acknowledging that this perspective is not universal among engineers; nevertheless, it stems from my own engineering background and experience. But here where we already arrived in the
								solution space, their participation is invaluable, I think.
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={2}>
							<Typography variant="h5">IDEATE</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								Many times I run brainstorming with various stakeholders and in multiple sessions we might find an intersect of great ideas. In this project we took thess steps:
								<ul>
									<li>Refined the RISK analysis done previously as we could extend it for all the risk types (Business, Value, Usability and Feasibility)</li>
									<li>Ideated 8 different solutions or solution variations.</li>
									<li>Shortlisted 3 different approach</li>
									<li>Created high-fidelity prototypes (in Figma) for all three</li>
									<li>Created clickable prototypes (in MAZE) and distributed in the target segment</li>
									<li>Ran multiple design reviews with internal/external stakeholders and experts</li>
									<li>Merged the three prototypes into a dynamic and clickable one</li>
									<li>Went for another round with the "final" prototype with stakeholders and experts</li>
								</ul>
							</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								The juncture arrived to delve deeper into the intricacies of feasibility risks, prompting me to engage the engineering team more extensively. With a focus on precision, I enlisted their expertise to provide an informed "guesstimate," leveraging the dynamic prototype
								we had crafted. I formalized the initiative as an EPIC within our JIRA system and the engineers torn it down into bite size pieces. We reviewed them all together and the project was ready for implementation.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_jira.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								JIRA EPIC (blurred)
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h4" pt={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								MATERIALIZE
							</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								During this phase, my role becomes more supportive compared to the earlier stages. I establish the measurement framework for development, yielding valuable KPIs that guide our progress. The engineering manager predominantly assists the team, collaborating closely with
								them. In our biweekly demo sessions, the engineers present their advancements, occasionally seeking my insights on specific aspects. I might also conduct minor tests, address queries, or assess implemented features from a product perspective. My involvement continues
								to extend to leading scrum ceremonies, fostering a strong bond with the team.
								<br />
								<br />
								It is also essential to decide how and what usage data we need to track. Fortunately, the user journeys at this point are known so it is easy to mirror them in the Jira EPIC.
								<br />
								<br />
								Allow me to showcase the tracking system I employed. This tool empowered me to forecast the achievable work for the upcoming sprint based on data from past progress. The interplay of insights and metrics made this phase an enjoyable and rewarding experience.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_velocity_1.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Velocity tracker (blurred)
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<img src={"/project_velocity_2.png"} alt="logo" width={"100%"} style={{ border: "1px solid #efefef" }} />
						<Box sx={{ textAlign: "center" }}>
							<Typography variant="body2" pt={0} sx={{ fontWeight: "400" }}>
								Engineering work tracker (blurred)
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h4" pt={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								EVALUATE
							</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								During this phase, the solution is live for users, warranting an evaluation to ensure we've not only implemented things right but have implemented the right things. If necessary, pivoting or refinement may be required. I acknowledge that certain product insights
								emerged during the materialization phase, which may have eluded us during the initial discovery. Such occurrences are not uncommon and do not signal a crisis. I believe in segregating these elements from the original scope to prevent what I refer to as "scope hell."
								Throughout this stage, our evaluation encompasses:
								<ul>
									<li>Quantitative data collection: Usage data reported by the application. This could be limited by GDPR and various privacy regulations around the world</li>
									<li>Qualitative data collection: Interviews, usage observations, follow up sessions</li>
									<li>Monitoring community channels (directly or indirectly attached to the product and feature)</li>
								</ul>
								Thus concludes one cycle within our iterative process. While the initial circle was notably comprehensive and time-consuming, it by no means signifies the end. In fact, it serves as the foundation for subsequent cycles. The insights gleaned during this initial
								iteration, coupled with the outcomes from the evaluation phase, provide an excellent launching point for embarking on the journey once more!
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h4" pt={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								COMMUNICATE
							</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								This represents an invaluable addition – a pivotal aspect in today's landscape. The effective communication with stakeholders is paramount after delivery. Within this context, I classify the tasks into two primary categories:
								<ul>
									<li>
										Internal communication
										<ul>
											<li>Wrote a communication plan with a schedules what should happen and when</li>
											<li>Wrote the release notes</li>
											<li>Informed the internal community on Slack</li>
											<li>Presented it to the stakeholders (group by group)</li>
										</ul>
									</li>
									<li>
										External communication
										<ul>
											<li>Wrote a communication plan with a schedule what should happen and when</li>
											<li>Update the official community channels or organize the update if that was in other team's responsibility</li>
											<li>Wrote a blog post</li>
											<li>Publish the release notes on various mediums</li>
										</ul>
									</li>
								</ul>
								Moreover, what I've delineated here isn't a holy grail solution for every scenario. It's not a rigid template I apply uniformly. Diverse contexts necessitate tailored approaches. The key takeaway is my systematic methodology, enabling the identification of issues,
								advantages, and the formulation of optimal solutions. This approach is underpinned by a foundation of practical wisdom, bolstered by a suite of efficient tools. Once the problem space is discovered, addressing unmet needs in the solution space becomes significantly
								more manageable.
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
