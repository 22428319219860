import { Box, TextField } from "@mui/material";
import React, { FC } from "react";

import { useShortAnswerInput } from "react-google-forms-hooks";

export interface IShortAnswerInputProps {
	id: string;
	label: string;
	required: boolean;
}

export const ShortAnswerInput: FC<IShortAnswerInputProps> = (props: IShortAnswerInputProps) => {
	const { id, label, required } = props;
	const { register } = useShortAnswerInput(id);

	return (
		<Box pt={2} pb={2}>
			<TextField id="outlined-basic" label={label} variant="outlined" {...register()} fullWidth required={required ? true : false} />
			{/* <input type="text" {...register()} /> */}
		</Box>
	);
};
