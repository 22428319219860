import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function MarkushEditor() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "350px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							MARKUSH EDITOR
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							The{" "}
							<Link href="https://chemaxon.com/markush-editor-and-markushtools" target="_blank">
								Markush Editor
							</Link>{" "}
							is designed to assist chemists and researchers in the creation and analysis of complex Markush structures. Markush structures are used in chemical patents to represent families of related chemical compounds that share certain core features or substitutions. These
							structures are essential in describing a broad range of potential chemical variations within a patent claim. The Markush Editor enables users to draw and edit Markush structures efficiently, making it easier to represent diverse chemical space and explore different
							permutations of compounds within a patent claim. It supports advanced Markush-specific features, such as generic structures, variable groups, and recursive R-groups, providing researchers with a powerful and user-friendly tool for working with complex chemical patents.
						</Typography>
						<Box pt={4}>
							<Typography variant="h5">About the app</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								It was one of the most promising application of the company. It showed a steady growth in user number and revenue year by year. It is a standalone application for Windows, Mac and Linux.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								Upon assuming ownership of the application, I became the sole product manager responsible for its management. The application comprises the Markush Editor and Markush Composer, integrated with various other components from the company's toolset. For convenience, I
								referred to them collectively as the Markush Editor since they were often sold together. The task was challenging as I had not previously managed standalone apps, and the application's domain involved hardcore chemistry, whereas my background lay in technology.
								Furthermore, the absence of a dedicated business analyst in the team necessitated a steep learning curve for me to effectively navigate and comprehend the intricacies of the domain.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Tasks</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>
										Product and Strategy
										<ul>
											<li>
												Define <strong>Vision</strong>, <strong>Mission</strong> and <strong>Strategy</strong>
											</li>
											<li>
												Define the <strong>Objectives</strong> and <strong>Key Results</strong> to achieve business and user value
											</li>
											<li>Align objectives with the company goals</li>
											<li>
												Perform risk analysis
												<ul>
													<li>Business, Value, Usability, Feasibility</li>
												</ul>
											</li>
											<li>Perform competitor and market analysis</li>
											<li>Enforce data-driven decision making</li>
											<li>Manage internal and external stakeholders</li>
											<li>Define and measure success</li>
											<li>Maximize product value</li>
											<li>Identify opportunities for growth</li>
											<li>Identify new market segments</li>
										</ul>
									</li>
									<li>
										Discovery
										<ul>
											<li>Execute extensive product researches (favoring the Design Thinking approach)</li>
											<li>Execute extensive UX researches</li>
										</ul>
									</li>
									<li>
										Leadership
										<ul>
											<li>People management</li>
											<li>Build trust internally/externally</li>
											<li>Conduct the Scrum methodology with its ceremonies</li>
										</ul>
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Achievements</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Successfully identified new market segments</li>
									<li>Penetrated into new markets and customers who were traditionally closed for offerings</li>
									<li>Implementing cutting-edge algorithms to assist in Markush structure prediction, analysis, and enumeration</li>
									<li>World-class data visualization to provide deeper insights into Markush structures and their variations</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Tools</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Confluence, Jira, draw.io, Google Apps, Google Forms</li>
								</ul>
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={2} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_markush_screen_1.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_markush_screen_2.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_markush_screen_3.png"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
