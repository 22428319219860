import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import { Header } from "../controls/Header";
import { JdContext } from "../helper/JdContext";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { theme } from "../helper/JdTheme";
import { GoogleForm, GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";
import { ShortAnswerInput } from "../controls/ShortAnswerInput";
import { LongAnswerInput } from "../controls/LongAnswerInput";
import { sxButtonCallToAction } from "../helper/commonStyles";
import { NavigationLinks } from "../helper/NavigationLinks";

const formJsonMentoring: GoogleForm = {
	fvv: 1,
	pageHistory: 0,
	fbzx: "4865024709595024906",
	action: "e/1FAIpQLSfey6YkbnUftVwbSeRKuUm7tXHyQ6vgdgtqlQ45tg4wmcGImw",
	title: "Mentorship Inquiry",
	description: "",
	fields: [
		{
			label: "Name",
			description: "",
			type: "SHORT_ANSWER",
			id: "2005620554",
			required: true,
		},
		{
			label: "Email",
			description: "",
			type: "SHORT_ANSWER",
			id: "1045781291",
			required: true,
		},
		{
			label: "Phone number",
			description: "",
			type: "SHORT_ANSWER",
			id: "1166974658",
			required: false,
		},
		{
			label: "Comments",
			description: "",
			type: "LONG_ANSWER",
			id: "839337160",
			required: false,
		},
	],
	fieldsOrder: { "839337160": 3, "1045781291": 1, "1166974658": 2, "2005620554": 0 },
};

export default function Mentoring() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");
	const [feedback, setFeedback] = useState("");

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const methods = useGoogleForm({ form: formJsonMentoring });
	const onSubmit = async (data: any) => {
		await methods.submitToGoogleForms(data);
		setFeedback("Thank you for your interest! My pleasure!");
	};

	const ContentElement = () => {
		return (
			<React.Fragment>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
						{/* PRODUCT CARDS */}
						<Grid item xs={12} md={6} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
							<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								MENTORSHIP
							</Typography>
							<Typography variant="body1" mt={4}>
								Allow me to shed light on the purpose behind this page, which is rooted in historical reasons. As you peruse my <Link href={NavigationLinks.RESUME}>resume page</Link>, you'll notice that my journey as a product professional was met with considerable challenges. Back
								then, I lacked mentors, examples, and adequate support, which compelled me to navigate the learning process independently. However, this experience became invaluable, shaping my growth and insights as a product leader.
								<br />
								<br />
								As my knowledge expanded, I felt compelled to share it both within the companies I worked for and beyond, participating in meet-ups, conducting workshops, and delivering talks in the product management field. I found myself aiding others who were on a similar path,
								and this mentoring-like engagement continued throughout my career. Individuals reached out to seek my advice and glean from my experiences, a role I hadn't fully realized I was already fulfilling.
								<br />
								<br />
								Thankfully, I now recognize this opportunity, and I am eager to continue offering my assistance to those who need it. This isn't about money or formal classes; it's a collaborative exchange where you approach me and we can discuss how I can be of help. I also find joy
								in the prospect that you might contribute to my learning journey as well. Together, we can foster growth and mutual learning in the product management domain.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
							<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								EXPRESS YOUR INTEREST
							</Typography>
							{feedback && (
								<Typography variant="h3" mt={2}>
									{feedback}
								</Typography>
							)}
							<Box pt={3}>
								<GoogleFormProvider {...methods}>
									<ShortAnswerInput label={"Name"} id={"2005620554"} required={true}></ShortAnswerInput>
									<ShortAnswerInput label={"Email"} id={"1045781291"} required={true}></ShortAnswerInput>
									<ShortAnswerInput label={"Phone Number"} id={"1166974658"} required={false}></ShortAnswerInput>
									<LongAnswerInput label={"Comments"} id={"839337160"} required={false}></LongAnswerInput>
								</GoogleFormProvider>
							</Box>
							<Box>
								<Button sx={{ ...sxButtonCallToAction, width: "100%" }} type="submit">
									SEND
								</Button>
							</Box>
						</Grid>
					</Grid>
				</form>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
