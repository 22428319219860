/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { FC, useContext } from "react";
import { JdContext } from "../helper/JdContext";


//**********************************************************************************************
// INTERFACE
//**********************************************************************************************
export interface IProgressIndicatorProps {
	open: boolean;
}

//**********************************************************************************************
// COMPONENT
//**********************************************************************************************
export const ProgressIndicator: FC<IProgressIndicatorProps> = (props: IProgressIndicatorProps) => {
	const context = useContext(JdContext);
	const { open } = props;

	const sxContainerStyles: SxProps = {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		backgroundColor: "#ffffff",
		height: "100%",
		zIndex: 999999,
		opacity: 0.7,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	//**********************************************************************************************
	// RENDER
	//**********************************************************************************************
	return (
		<React.Fragment>
			{open && (
				<Box sx={sxContainerStyles}>
					<Box>
						<CircularProgress disableShrink />
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
};
