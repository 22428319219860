import { theme } from "./JdTheme";

export const sxButtonCallToAction = {
	border: "1px solid #fff",
	color: "#fff",
	fontWeight: "800",
	padding: "16px",
	backgroundColor: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: "#fff",
		border: "1px solid " + theme.palette.primary.main,
	},
};
