import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import { Header } from "../controls/Header";
import { JdContext } from "../helper/JdContext";
import { Button, Grid, Link, Typography } from "@mui/material";
import { theme } from "../helper/JdTheme";
import { GoogleForm, GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";
import { ShortAnswerInput } from "../controls/ShortAnswerInput";
import { LongAnswerInput } from "../controls/LongAnswerInput";
import { sxButtonCallToAction } from "../helper/commonStyles";

const formJson: GoogleForm = {
	fvv: 1,
	pageHistory: 0,
	fbzx: "2978444303287906798",
	action: "e/1FAIpQLSeznvUzWxxgcPJJhj_0dIZJHCNz8xFDPBQawyAAknzuUENHrw",
	title: "Contact Jozsef David",
	description: null as any as string,
	fields: [
		{
			label: "Name",
			description: null as any as string,
			type: "SHORT_ANSWER",
			id: "2005620554",
			required: true,
		},
		{
			label: "Email",
			description: null as any as string,
			type: "SHORT_ANSWER",
			id: "1045781291",
			required: true,
		},
		{
			label: "Phone number",
			description: null as any as string,
			type: "SHORT_ANSWER",
			id: "1166974658",
			required: false,
		},
		{
			label: "Comments",
			description: null as any as string,
			type: "LONG_ANSWER",
			id: "839337160",
			required: false,
		},
	],
	fieldsOrder: { "839337160": 3, "1045781291": 1, "1166974658": 2, "2005620554": 0 },
};

export default function Contact() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");
	const [feedback, setFeedback] = useState("");
	const methods = useGoogleForm({ form: formJson });
	const onSubmit = async (data: any) => {
		await methods.submitToGoogleForms(data);
		setFeedback("Thank you for contacting me. I am thrilled");
	};

	const coffeeLink = {
		border: "1px solid #fff",
		borderRadius: "8px",
		"& :hover": {
			borderRadius: "8px",
			border: "1px solid #cccccc",
		},
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
						{/* PRODUCT CARDS */}
						<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
							<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
								CONTACT
							</Typography>
							<Typography variant="body2" mt={2}>
								What you should expect after got in touch with me: I respond, except you try to sell something to me.
							</Typography>
							{feedback && (
								<Typography variant="h3" mt={2}>
									{feedback}
								</Typography>
							)}
						</Grid>
						<Grid item xs={12}>
							<GoogleFormProvider {...methods}>
								<ShortAnswerInput label={"Name"} id={"2005620554"} required={true}></ShortAnswerInput>
								<ShortAnswerInput label={"Email"} id={"1045781291"} required={true}></ShortAnswerInput>
								<LongAnswerInput label={"Comments"} id={"839337160"} required={false}></LongAnswerInput>
							</GoogleFormProvider>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button sx={{ ...sxButtonCallToAction, width: context.mediaXS || context.mediaSM ? "100%" : "200px" }} type="submit">
								SEND
							</Button>
						</Grid>
						<Grid item xs={12} sm={6} sx={{ textAlign: context.mediaXS ? "center" : "right" }}>
							<Link href="https://www.buymeacoffee.com/jozsefdavid" target="_blank" sx={coffeeLink}>
								<img src={"/buymeacoffee.png"} alt="logo" width={"200px"} />
							</Link>
						</Grid>
					</Grid>
				</form>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
