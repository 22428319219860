import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function Walheda() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "550px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							WALHEDA
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							Walheda is a highly sophisticated Software as a Service (SaaS) platform designed for business automation and text processing. Its primary focus is on invoice understanding, allowing it to comprehend the structure of invoices and extract labeled data, even from images. The
							application excels in extracting key-value pairs from diverse document types and can efficiently handle table structures as well. While data extraction is a crucial aspect of the accounts payable workflow (for instance), we understand that integration into automated
							workflows is equally important. Hence, Walheda offers an extensive API, accompanied by illustrative examples, facilitating seamless integration into any automated workflow.
							<br />
							<br />
							With multiple subscription plans in place, we envision a monetization strategy for the application. Due to its complexity and potential for expansion, our plan involves selling the application at some point. Although we recognize its value and growth potential, our
							current limitation lies in the lack of required resources to make the necessary investment. Nonetheless, we remain committed to offering a sophisticated solution that can streamline business processes and enhance efficiency for our users.{" "}
						</Typography>

						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								My fascination with Natural Language Understanding drove the inception of this SaaS solution. As the sole product manager and full stack developer, I spearheaded the entire project. While I conducted several rounds of hypothesis validation before and during
								development, I must admit that time constraints limited the depth of my investment.
								<br />
								<br />
								Extensive competitor analysis laid the groundwork for the initial hypothesis, which formed the basis of our development efforts. Throughout the development process, I relied on qualitative validation, conducting interviews with users of similar systems, financial
								experts, and accountants. Social channels such as Reddit, Facebook, and LinkedIn served as valuable platforms to connect with these individuals.
								<br />
								<br />
								From a technical standpoint, the application relies on docker images encompassing seven different services and leverages the capabilities of both AWS and Azure. This combination of robust technology allows us to deliver a seamless and efficient user experience.
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={2} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_walheda_screen_1.gif"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_walheda_screen_2.gif"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
