import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function ChemicalizePro() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "300px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							CHEMICALIZE PROFESSIONAL
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							Chemicalize Professional is a subscription-based Software as a Service (SaaS) product catering to a diverse user base comprising scientists, chemical researchers, and chemists.{" "}
							<Link href="https://pro.chemicalize.com" target="_blank">
								pro.chemicalize.com
							</Link>{" "}
							is an advanced version of the Chemicalize platform offered by ChemAxon. It is a powerful tool designed for researchers, chemists, and scientists in various industries, such as pharmaceuticals, chemistry, and materials science. Chemicalize Professional provides additional
							capabilities and enhanced features beyond the standard Chemicalize service. The platform allows users to perform in-depth chemical calculations and predictions, access more extensive databases, and benefit from advanced analysis tools. It enables researchers to explore
							and understand chemical compounds in greater detail, including predicting physicochemical properties, toxicity endpoints, and other crucial data. The enhanced functionalities of Chemicalize Professional cater to professionals who require comprehensive and high-level
							chemical analysis to support their research and decision-making processes. Compared to the product Chemicalize, Chemicalize Professional targets the B2B market and the academia. Most users engage with the platform for project-specific needs, larger number of calculations
							at once or integrating chemical knowledge into their websites that drive project progression. Chemicalize Professional provides a detailed and well documented API enabling users to benefit from the calculation capacity offered by the service. Moreover, the application
							places significant emphasis on streamlining the subsequent steps in the workflow, thus offering various integration possibilities with third party systems.
						</Typography>

						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								As the sole product manager responsible for the Chemicalize experience, I assumed full ownership of the product. Upon joining the team, I observed that the initial version of the product had significant potential for improvements. My primary goal was to align the
								existing concept in order to drive increased revenue while retaining the same dedicated resources and keeping the alignment with the company objectives. Following an extensive research phase, we successfully introduced new payment concepts and strategically
								prioritized go-to-market strategies to achieve these objectives. For this product, I was allocated an annual budget and provided with necessary resources along with predefined outcomes to be achieved by the end of the year. I enjoyed complete autonomy in determining
								the most suitable strategies and actions required to deliver the expected results. This position required complete independent working capabilities coupled with an entrepreneurial mindset.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">My Tasks</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>
										Product and Strategy
										<ul>
											<li>Identify growth opportunities</li>
											<li>
												Define <strong>Vision</strong>, <strong>Mission</strong> and <strong>Strategy</strong>
											</li>
											<li>
												Define the quarterly <strong>Objectives</strong> and <strong>Key Results</strong> to achieve business and user value
											</li>
											<li>Align objectives with the company goals</li>
											<li>Define comprehensive value proposition for hosted search, compliance checking, chemical drawing, and chemical calculations</li>
											<li>Maximize product value</li>
											<li>Develop SaaS pricing</li>
											<li>
												Perform risk analysis
												<ul>
													<li>Business, Value, Usability, Feasibility</li>
												</ul>
											</li>
											<li>Maintain the product roadmap</li>
											<li>Prioritize backlog</li>
											<li>Enforce data-driven decision making</li>
											<li>Manage internal and external stakeholders</li>
											<li>Define and measure success</li>
											<li>Participate in marketing activities</li>
										</ul>
									</li>
									<li>
										Discovery
										<ul>
											<li>Explore and validate ideas on B2B market</li>
											<li>Execute extensive product researches using the Design Thinking approach</li>
											<li>Execute extensive market, competitor and UX researches</li>
											<li>Facilitate user visits and interviews on a weekly basis</li>
											<li>Demonstrate product virtually and in person for customers</li>
										</ul>
									</li>
									<li>
										Analytics
										<ul>
											<li>Oversee and measure the development efficacy (KPIs)</li>
											<li>Maintain full blown velocity metrics</li>
											<li>
												Define and measure usage data
												<ul>
													<li>Focusing on user segmentation, journey and path, funnels and cohort analysis</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										Leadership
										<ul>
											<li>People management</li>
											<li>Build trust internally/externally</li>
											<li>Conduct the Scrum methodology with its ceremonies</li>
											<li>Oversee the complete development lifecycle</li>
											<li>Define resource allocation</li>
											<li>Budgeting</li>
										</ul>
									</li>
									<li>
										Communication
										<ul>
											<li>Giving talks at various conferences worldwide</li>
											<li>Write blog posts and articles</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=IajV9ujGAvE&ab_channel=Chemaxon" target="_blank">
													Chemicalize presentation in Boston, USA (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=WXaedJ5Eb7w&ab_channel=Chemaxon" target="_blank">
													Chemicalize presentation in Budapest, Hungary (YouTube)
												</Link>
											</li>
											<li>
												<Link href="https://www.youtube.com/watch?v=1JxOuY0TXKA&t=384s&ab_channel=Chemaxon" target="_blank">
													Chemicalize Professional presentation (YouTube)
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Achievements</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Pivot the business model</li>
									<li>Gain a 1000% increase in the yearly income</li>
									<li>Penetrate new target segments</li>
									<li>Run successful go-to-market projects</li>
									<li>Build up the engineering team from scratch </li>
									<li>Successfully empowered and innovated the service</li>
									<li>Provide first of its kind services in the industry</li>
									<li>Penetrate the new concept in the industry</li>
								</ul>
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Tools</Typography>
							<Typography variant="body1" component={"div"} pt={0} sx={{ fontWeight: "400" }}>
								<ul style={{ textAlign: "left" }}>
									<li>Confluence, Jira, Miro, Google Analytics, draw.io, Google Apps, Google Forms, Custom Analytic Tools</li>
								</ul>
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={2} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_chemicalize_pro_screen_1.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_chemicalize_pro_screen_2.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_chemicalize_pro_screen_3.png"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
