import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Header } from "../../controls/Header";
import { JdContext } from "../../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../../helper/JdTheme";
import { NavigationLinks } from "../../helper/NavigationLinks";
import { BackButton } from "../../controls/BackButton";

export default function Rebezle() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");

	const sxImageBox = {
		width: "550px",
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<BackButton link={NavigationLinks.PRODUCTS_ALL_PRODUCTS} />
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							REBEZLE
						</Typography>
						<Typography variant="body1" pb={0} pt={4} sx={{ fontWeight: "400" }}>
							Presenting my latest side project, a platform tailored for home design enthusiasts, including constructors, architects, and interior designers to showcase their praiseworthy work. Concurrently, individuals seeking inspiration for their own projects can explore these
							remarkable designs. As this venture isn't my primary focus, progress is steady but gradual.
							<br />
							<br />
							Notably, this marks my first collaborative side project, where I partner with a skilled UX designer. Together, we embark on UX and product experiments, endeavoring to uncover patterns that resonate with our users. Through a combination of small quantitative and
							qualitative validations, we place significant emphasis on usage data.
							<br />
							<br />
							At present, this venture operates as a not-for-profit application, with the monetization aspect deferred until 2025. Consequently, achieving product-market fit remains a distant goal. Nevertheless, our primary focus revolves around experimenting with new hypotheses and
							innovative product management approaches.
							<br />
							<br />
							Our strategy centers on small incremental steps, consistently refining our value proposition, which may evolve over time. We approach each new hypothesis as a small project, following complete Discover-Design-Deliver cycles or embracing the Understand-Explore-Materialize
							framework of Design Thinking.
							<br />
							<br />
							While the monetization phase lies ahead, our current focus remains on fostering experimentation and embracing new ideas. I am more than willing to share additional insights into the project upon request.
						</Typography>
						<Box pt={4}>
							<Typography variant="h5">My Role</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								In this project, I hold a unique dual role as the sole product manager responsible for defining and executing short and long-term goals, vision, and strategy. Additionally, I take on the role of the full stack developer, single-handedly crafting the{" "}
								<Link href="https://rebezle.com" target="_blank">
									entire site
								</Link>{" "}
								you see upon opening. As a full-time product manager, I primarily focus on my professional expertise and won't delve into extensive technical details. However, I'm pleased to provide an overview of the technologies I employ to build and operate the application.
								<br />
								<br />
								The backend is developed using Node.js, while the frontend is built with React, both written in Typescript. To ensure seamless deployment and scalability, I create Docker images that run on a Virtual Linux machine in the cloud. For the application's infrastructure, I
								make use of AWS and Azure technologies simultaneously, leveraging their respective strengths to enhance the project's performance and reliability.
							</Typography>
						</Box>
						<Box pt={4}>
							<Typography variant="h5">Mobile First</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								Ensuring a consistent user experience across all devices was one of our core objectives for this project. Regardless of whether users accessed the site on a 4K screen or a low-end mobile device, we aimed to deliver content without compromise. I encourage you to
								explore the site on various devices and resolutions to witness this seamless experience firsthand. It's a fascinating aspect in my view—how to provide equal value regardless of the screen real estate available. Our mobile-first approach played a crucial role in
								achieving this goal.
								<br />
								<br />
								While the topic of delivering a uniform user experience across devices is extensive, I won't delve into exhaustive details here. However, I'd be delighted to discuss how we approached and experimented to provide maximum user value in more depth. The exploration of
								this subject has been an exciting journey, and I'd love to share insights and insights gained from our efforts.
							</Typography>
						</Box>
					</Grid>
					{/* PRODUCT PICTURES */}
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box display={"flex"} gap={5} flexWrap={"wrap"}>
							<Box sx={sxImageBox}>
								<img src={"/product_rebezle_screen_1.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_rebezle_screen_2.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_rebezle_screen_3.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/product_rebezle_screen_4.png"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
