/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import React, { FC } from "react";
import { IconBack } from "../helper/Icons";
import { useNavigate } from "react-router-dom";

//**********************************************************************************************
// INTERFACE
//**********************************************************************************************
export interface IBackButtonProps {
	link: string;
}

//**********************************************************************************************
// COMPONENT
//**********************************************************************************************
export const BackButton: FC<IBackButtonProps> = (props: IBackButtonProps) => {
	const { link } = props;
	const navigate = useNavigate();

	const sxBackBox = {
		position: "fixed",
		top: "100px",
		left: "24px",
		width: "60px",
		height: "60px",
		borderRadius: "50%",
		cursor: "pointer",
		// backgroundColor: "#fff",
	};
	//**********************************************************************************************
	// RENDER
	//**********************************************************************************************
	return (
		<React.Fragment>
			<Box
				sx={sxBackBox}
				onClick={() => {
					navigate(link);
				}}
			>
				<IconBack width={60} height={60} fill={"#002d5b"} stroke={"none"} />
			</Box>
		</React.Fragment>
	);
};
